import './App.css';
function App() {
  return (
    <div>
          This is a ecommerce wesite IT-CONNECT-ZA .. Coming soon

    </div>
  );
}

export default App;
